import type {
  ActionFunctionArgs,
  LoaderFunctionArgs,
  MetaFunction,
} from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Form,
  Link,
  useActionData,
  useLocation,
  useNavigation,
} from "@remix-run/react";
import httpStatus from "http-status";
import Joi from "joi";
import lodash from "lodash";
import { LockIcon, Mail } from "lucide-react";
import qs from "qs";
import { useTranslation } from "react-i18next";
import LeftArrow from "~/assets/svg/LeftArrow";
import TextField from "~/components/Fields/TextField";
import * as cookies from "~/cookies";
import { postLogin } from "~/services/api";
import to from "~/utils/to";
import toLatinDigits from "~/utils/toLatinDigits";

const schema = Joi.object({
  identifier: Joi.string().required(),
  password: Joi.string().required(),
});

export async function loader({ request }: LoaderFunctionArgs) {
  await cookies.auth.unrequired(request);
  const params = qs.parse(new URL(request.url).search, {
    ignoreQueryPrefix: true,
  });

  return { params };
}

export const meta: MetaFunction = () => {
  const { t } = useTranslation("translation");
  return [
    {
      title: t("login") + " | " + t("title"),
      description: t("description"),
    },
  ];
};

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const values = Object.fromEntries(formData);
  values.identifier = toLatinDigits(values.identifier);
  let errors: { [key: string]: string } = {};

  schema
    .validate(values, { abortEarly: false })
    .error?.details.forEach((detail) => {
      errors[detail.path.join(".")] = `errors.${detail.type}`;
    });
  if (!lodash.isEmpty(errors)) return json({ errors, values });

  const [err, res] = await to(postLogin({ data: values }));
  if (err) {
    if (err.status === httpStatus["FORBIDDEN"]) {
      errors = {
        identifier: "auth.login.invalid",
        password: "auth.login.invalid",
      };
    } else throw err;
    if (!lodash.isEmpty(errors)) return json({ errors, values });
  }

  return cookies.auth.login(request, res.data);
}

function Login() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const actionData = useActionData<typeof action>();
  const navigation = useNavigation();

  return (
    <div className="min-h-lvh flex flex-col justify-center items-center bg-[#f1f2f2]">
      <img
        className="w-full h-[50vh] left-0 top-0 absolute"
        style={{ backgroundImage: "url(/svg/auth_bg.svg)" }}
      />
      <div className="max-w-[380px] w-full relative z-10 px-2">
        <div className="p-6 rounded-2xl bg-base-100 gap-8 flex flex-col">
          <div className="flex justify-center flex-col text-center gap-2 mt-4">
            <h1 className="font-bold text-lg lg:text-2xl">
              {t("auth.loginToHidSim")}
            </h1>
            <h3 className="text-sm lg:text-base text-neutral-500">
              {t("auth.enterYourCredentials")}
            </h3>
          </div>
          <Form
            method="post"
            action={"/login" + location.search}
            reloadDocument={false}
          >
            <fieldset
              className="flex flex-col gap-2"
              disabled={navigation.state === "submitting"}
            >
              <TextField
                input={{
                  type: "text",
                  name: "identifier",
                  defaultValue: actionData?.values?.identifier,
                  placeholder: t("auth.login.email.address"),
                }}
                error={actionData?.errors?.identifier}
                errorMode="subtitle"
                preIcon={<Mail size={18} />}
              />
              <TextField
                input={{
                  type: "password",
                  name: "password",
                  defaultValue: actionData?.values?.password,
                  placeholder: t("auth.login.password"),
                }}
                error={actionData?.errors?.password}
                errorMode="subtitle"
                preIcon={<LockIcon size={18} />}
              />

              {/* <Link className="text-center text-xs my-2 lg:my-6">
                {t("auth.forgotYourPassword")}
              </Link> */}
              <button
                className="btn btn-block bg-[#a8efc0] rounded-full text-neutral-900 text-base font-['Figtree', 'IRANYekanXVF'] mt-4"
                type="submit"
              >
                {navigation.state === "submitting" ? t("wait") : t("login")}
              </button>
              <div className="text-center text-xs">
                <a className="text-underline dotted" href="/forgot">
                  {t("auth.forgotYourPassword")}
                </a>
              </div>
            </fieldset>
          </Form>
        </div>
        <div className="text-center mt-10 gap-4 mx-4 flex flex-col items-center">
          <Link
            className="text-underline dotted"
            to={"/register" + location.search}
            prefetch="render"
          >
            {t("auth.notHaveAccount")}
          </Link>
          <Link
            className="text-neutral-900 w-full text-base h-12 px-6 py-3 bg-base-100 rounded-full justify-center items-center gap-2 flex font-semibold font-['Figtree', 'IRANYekanXVF']"
            to={"/register" + location.search}
            prefetch="render"
          >
            {t("auth.registerNow")}
            <span className="mx-0.5" />
            <LeftArrow
              className="inline w-4"
              style={
                i18n.dir() === "ltr" ? { transform: "rotateZ(180deg)" } : {}
              }
              fill="currentColor"
              fillRule="evenodd"
            />
          </Link>
        </div>
      </div>

      <Link
        to="/services"
        className="justify-center flex text-center items-center w-full absolute bottom-12 font-semibold font-['Figtree', 'IRANYekanXVF']"
        prefetch="render"
      >
        {t("auth.backToHome")}
      </Link>
    </div>
  );
}

export default Login;
